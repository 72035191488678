// Styles for accordion component.

.c-panel__title {
    display: flex;
    align-items: center;
    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: dotted $color-pink-100 2px;
    }

    @include at-min($bp-large) {
        font-size: $font-size-3;
    }
}

.c-panel__content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s;

    .is-expanded & {
        grid-template-rows: 1fr;
    }

    > div {
        overflow: hidden;
    }
}

.c-panel__icon {
    margin-left: $space-1;
    color: $color-pink-90;
    transition: transform 0.2s;
    transform: rotateX(0);
    flex: none;

    .is-expanded & {
        transform: rotateX(-180deg);
    }

    svg {
        font-size: 150%;
    }
}
