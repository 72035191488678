// Global resets and element styles.

:root {
    color-scheme: light dark;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    background-repeat: no-repeat;
}

html,
body {
    position: relative;
    overflow-x: hidden;
    height: 100%;
    min-height: 100%;
}

body {
    // Remove gray highlight when tapping links in mobile Safari
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

    // Disables pull-to-refresh and overscroll glow effect.
    // Still keeps swipe navigations.
    overscroll-behavior-y: none;

    // Set base text color and inverted version for dark mode.
    color: $color-gray-100;

    @include dark-mode {
        color: $color-white;
        background-color: $color-dark-gray-100;
    }
}

[id='root'] {
    min-height: 100%;

    // Push footer to bottom if no scroll present
    display: flex;
    flex-direction: column;

    > header,
    > footer {
        flex: none;
    }

    > main {
        flex: 1;
    }

    // Hide footer if no main content exists
    > main:empty + footer {
        display: none;
    }
}

dl,
dd,
ol,
ul,
li,
form,
fieldset,
legend,
hr {
    margin: 0;
    padding: 0;
}

button,
input,
select,
textarea {
    // Completely resets form items
    border: 0;
    border-radius: 0;
    appearance: none;
    background: transparent;

    // Inherit styling
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    font-weight: inherit;
}

a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
    touch-action: manipulation;
}

[role='dialog'][aria-hidden='true'] {
    visibility: hidden;
}

// Override Chrome default autofill color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 $space-16 $color-gray-05 inset;
    -webkit-text-fill-color: $color-gray-100 !important;
}

hr {
    border: 0;
    border-bottom: 2px solid currentcolor;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

:focus {
    outline: $color-purple-80 auto 1px;
}

// Disable focus style when user is not using keyboard.
// https://css-tricks.com/the-focus-visible-trick/
:focus:not(:focus-visible) {
    outline: none !important;
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
// Credit: https://github.com/suitcss/base
[tabindex='-1']:focus {
    outline: none !important;
}

[hidden] {
    display: none !important;
}

audio,
canvas,
img,
svg,
video,
iframe,
embed {
    vertical-align: middle;
    max-width: 100%;
}

img,
video {
    height: auto;
    font-style: italic;
}

figure > img {
    display: block;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

textarea {
    resize: vertical;

    &:active,
    &:focus {
        cursor: text;
    }
}

label,
button {
    cursor: pointer;
}

// Table globals
table {
    border-collapse: collapse; // Prevent double borders
    border-spacing: 0;
    width: 100%;
}

th {
    text-align: left;
}

tr {
    vertical-align: top;
}

// Reset <progress> element
progress {
    appearance: none;
}

progress::-moz-progress-bar {
    background: transparent;
}

progress::-webkit-progress-bar {
    background: transparent;
}

// Remove list styles from unordered and ordered lists
ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

// Reset font style for <address> block
address {
    font-style: normal;
}

// Anything that has been anchored to should have extra scroll margin
// https://andy-bell.co.uk/a-more-modern-css-reset/
:target {
    scroll-margin-block: $header-height;
}
