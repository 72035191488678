// FONT

$font-name: 'gilroy-webfont';

// TYPE SCALE

$font-size-1: 14px;
$font-size-2: 16px;
$font-size-3: 20px;
$font-size-4: 24px;
$font-size-5: 26px;
$font-size-6: 28px;
$font-size-7: 32px;
$font-size-8: 36px;
$font-size-9: 42px;
$font-size-10: 48px;
$font-size-11: 52px;
$font-size-12: 64px;

// FONT WEIGHTS

// gilroy is only loaded in 400,500,700 weights
$font-thin: 200;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

// LEADING

$line-height-1: 1;
$line-height-2: 1.125;
$line-height-3: 1.2;
$line-height-4: 1.25;
$line-height-5: 1.3;
$line-height-6: 1.5;

$base-font-size: $font-size-2;
$base-line-height: $line-height-2;
$base-font-weight: $font-medium;
$base-tracking: -0.27px;

// SPACING

$spacing-base: 5px;

$space-1: $spacing-base * 1; // 5
$space-2: $spacing-base * 2; // 10
$space-3: $spacing-base * 3; // 15
$space-4: $spacing-base * 4; // 20
$space-5: $spacing-base * 5; // 25
$space-6: $spacing-base * 6; // 30
$space-8: $spacing-base * 8; // 40
$space-10: $spacing-base * 10; // 50
$space-12: $spacing-base * 12; // 60
$space-14: $spacing-base * 14; // 70
$space-16: $spacing-base * 16; // 80

$footer-height: $spacing-base * 55;
$header-height: $space-12;
$logo-wide: $spacing-base * 40; // 200px
$nav-drawer: $spacing-base * 100; // 500px
$hero-cta-width: $spacing-base * 52; // 260px
$mobile-hero-width: $spacing-base * 55; // 275px
$trustpilot-height: $spacing-base * 55; // 275px

$icon-large: 40px;

// BREAKPOINTS
// Reference: https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
// ranges:
// xs: 0 - 600
// sm: 600 - 900
// md: 900 - 1200
// lg: 1200 - 1800
// xl: 1800+

$bp-tiny: 375;
$bp-small: 600; // at-min: tablet-portrait, at-max: phone
$bp-medium: 900; // at-min: tablet-landscape
$bp-large: 1200; // at-min: desktop
$bp-huge: 1800; // at-min: hd or tv

// spacing values that match breakpoints
$space-tiny: 400px;
$space-small: 600px;
$space-medium: 900px;
$space-large: 1200px;

// BORDER-RADIUS

$radius: (
    'tiny': 3px,
    'small': 6px,
    'medium': 12px,
    'large': 24px,

    'pill': 99rem,
    'circle': 50%,
);

// Z-INDEX

$z-layers: (
    'video': 6,
    'c-modal': 5,
    'zendesk': 5,
    'loader': 4,
    'header': 3,
    'card__image': 2,
    'header_menu': 2,
    'header_nav': 1,
    'card__number': 1,
    'spinner': 1,
    'play-btn': 1,
    'modal-close': 1,
    'slides': 1,
);
