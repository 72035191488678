.c-card {
    position: relative;
    background: $color-white;
    border-radius: br('medium');
    border: 4px solid transparent;
    padding: $space-4;
    margin-bottom: $space-3;

    &.is-active {
        &.is-flex {
            border-color: $color-pink-90;

            .c-card__title {
                color: $color-pink-90;
            }
        }
        &.is-unlimited {
            border-color: $color-purple-100;

            .c-card__title {
                color: $color-purple-100;
            }
        }
    }

    @include dark-mode {
        background: $color-dark-gray-100;
    }
}

.c-card__media {
    display: flex;
    align-items: flex-start;
    margin-bottom: $space-3;

    > img {
        flex: none;
        margin-right: $space-3;
        margin-top: $space-1;
    }

    > *:not(img) {
        flex: 1 1 auto;
    }
}

.c-card__header {
    @include at-min($bp-medium) {
        min-height: $spacing-base * 30; // 150px
    }

    @include at-min($bp-large) {
        min-height: $spacing-base * 34; // 170px
    }
}

.c-card__title {
    font-size: $font-size-4;
    font-weight: $font-bold;
    line-height: $line-height-3;
    margin-bottom: 0;

    @include at-min($bp-large) {
        letter-spacing: track(15);
        font-size: $font-size-6;
    }
}

.c-card__subtitle {
    margin-bottom: $space-3;

    @include at-min($bp-large) {
        font-size: $font-size-3;
    }
}

.c-card__content-icon {
    width: $space-6;
}
