// Utility spacing classes for setting margin and padding.

$spacing-map: (
    'auto': auto,
    '0': $space-1 * 0,
    '1': $space-1,
    '2': $space-2,
    '3': $space-3,
    '4': $space-4,
    '6': $space-6,
    '8': $space-8,
    '10': $space-10,
    '12': $space-12,
) !default;

$helpers: (
    'm': 'margin',
    'mt': 'margin-top',
    'mr': 'margin-right',
    'mb': 'margin-bottom',
    'ml': 'margin-left',
    'mx': 'margin-right' 'margin-left',
    'my': 'margin-top' 'margin-bottom',

    'p': 'padding',
    'pt': 'padding-top',
    'pr': 'padding-right',
    'pb': 'padding-bottom',
    'pl': 'padding-left',
    'px': 'padding-right' 'padding-left',
    'py': 'padding-top' 'padding-bottom',
);

// Loop over the helpers in $helpers
@each $alias, $property in $helpers {
    // Loop over spacing values in $spacing-map
    @each $key, $value in $spacing-map {
        // Create a new class
        .u-#{$alias}-#{$key} {
            // If helper contains several properties, loop over them
            @if length($property) > 1 {
                @each $prop in $property {
                    #{$prop}: $value !important;
                }
            } @else {
                // If helper is a single property, output it
                #{$property}: $value !important;
            }
        }
    }
}

// Repeat previous loop to generate spacing classes for each
// defined breakpoint.

$breakpoints: (
    'sm': $bp-small,
    'md': $bp-medium,
    'lg': $bp-large,
);

/* stylelint-disable max-nesting-depth */
@each $bp-name, $bp-value in $breakpoints {
    @each $alias, $property in $helpers {
        @each $key, $value in $spacing-map {
            @include at-min($bp-value) {
                .#{$bp-name}\:u-#{$alias}-#{$key} {
                    @if length($property) > 1 {
                        @each $prop in $property {
                            #{$prop}: $value !important;
                        }
                    } @else {
                        #{$property}: $value !important;
                    }
                }
            }
        }
    }
}
