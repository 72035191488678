.c-table {
    border-spacing: 0;
    font-size: $font-size-2;
    max-width: 100%;

    @include at-min($bp-small) {
        table-layout: fixed;
    }

    td,
    th {
        vertical-align: top;
        padding: $space-2;
    }

    th {
        text-align: left;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid $color-gray-20;
    }
}

.c-table--rounded {
    border-radius: br('medium');
    overflow: hidden;
}

.c-table--horizontal {
    tr {
        td {
            padding-bottom: $space-3;
            padding-top: $space-3;
        }

        td:first-child {
            // Acts as the "header"
            font-size: $font-size-2;
            font-weight: $font-bold;
        }

        &:not(:last-child) {
            border-bottom: 0;
        }
    }
}

.c-table--striped {
    --bg-odd: #{$color-white};
    --bg-even: #{$color-purple-10};

    @include dark-mode {
        --bg-odd: #{$color-dark-gray-95};
        --bg-even: #{$color-dark-gray-90};
    }
    tr {
        border: 0 !important;
        &:nth-of-type(even) {
            background-color: var(--bg-even);
        }
        &:nth-of-type(odd) {
            background-color: var(--bg-odd);
        }
    }
}

.c-table--spacey {
    td,
    th {
        padding: $space-3;
    }
}

.c-table--flexible {
    table-layout: auto;
}

.c-table--fixed {
    table-layout: fixed;
}

.c-table--clean {
    margin: negative($space-1);

    td,
    th {
        padding: $space-1;
    }

    tr {
        border-bottom: 0 !important;
    }
}

.c-table--bleed {
    td:first-child,
    th:first-child {
        padding-left: 0;
    }
    td:last-child,
    th:last-child {
        padding-right: 0;
    }
}

// Responsive table
// Wrap table in `.c-table--responsive` to make them mobile friendly
// by enabling horizontal scrolling.
.c-table--responsive {
    background: $color-white;
    padding-bottom: 0;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;

    @include at-max($bp-tiny) {
        border: 1px solid $color-gray-10;
        border-radius: br('small');
    }

    .c-table {
        @include at-max($bp-tiny) {
            border: 0;
        }
    }

    .c-table--fixed {
        min-width: $space-tiny;
    }
}

.c-compare-table {
    --bg-base: #{$color-white};
    --bg-alt: #{$color-gray-05};
    --border: #{$color-purple-10};

    @include dark-mode {
        --bg-base: #{$color-dark-gray-100};
        --bg-alt: #{$color-dark-gray-90};
        --border: #{$color-gray-80};
    }

    font-size: $font-size-2;
    table-layout: fixed;
    min-width: 100%;

    @include at-min($bp-medium) {
        font-size: $font-size-3;
    }

    td,
    th {
        vertical-align: middle;
        padding: $space-3;
        text-align: center;
        font-weight: $font-medium;

        @include at-min($bp-medium) {
            padding: $space-4;
        }
    }

    tbody tr {
        &:nth-of-type(odd) {
            background-color: var(--bg-alt);
        }
        &:nth-of-type(even) {
            background-color: var(--bg-base);
        }
    }

    // icon column width
    tr > *:not(:first-child) {
        width: 100px;

        @include at-min($bp-small) {
            width: 160px;
        }
    }

    // first column w/ copy
    tr > *:first-child {
        width: 145px;

        @include at-min($bp-small) {
            width: 300px;
        }

        @include at-min($bp-medium) {
            width: 380px;
        }
    }

    tbody th {
        text-align: left;
    }

    thead tr {
        background-color: var(--bg-base);
    }
}

.c-compare-table__hugo {
    position: relative;
    z-index: 1;

    &:before {
        @include absolute(0 0 0 0);
        content: '';
        background: $color-purple-10;
        opacity: 0.5;
        z-index: -1;

        @include dark-mode {
            background-color: $color-dark-purple-01;
        }
    }
}

th.c-compare-table__hugo {
    &:before {
        border-top-left-radius: br('medium');
        border-top-right-radius: br('medium');
    }
}

.c-compare-table tbody tr:last-child .c-compare-table__hugo {
    &:before {
        border-bottom-left-radius: br('medium');
        border-bottom-right-radius: br('medium');
    }
}

.c-compare-table__comp {
    position: relative;
    &:before {
        @include absolute(0 0 0 0);
        content: '';
        border-left: 1px solid var(--border);
        border-right: 1px solid var(--border);
    }
}

th.c-compare-table__comp {
    border-left: 0;
    border-right: 0;
    padding-left: $space-1;
    padding-right: $space-1;

    &:before {
        @include absolute(0 0 0 0);
        content: '';
        border: 1px solid var(--border);
        border-bottom: 0;
        border-top-left-radius: br('medium');
        border-top-right-radius: br('medium');
    }
}

tbody tr:last-child .c-compare-table__comp {
    border-left: 0;
    border-right: 0;

    &:before {
        @include absolute(0 0 0 0);
        content: '';
        border: 1px solid var(--border);
        border-top: 0;
        border-bottom-left-radius: br('medium');
        border-bottom-right-radius: br('medium');
    }
}

.c-compare-table__sticky {
    position: sticky;
    z-index: 2;
    background-color: inherit;
}

th.c-compare-table__sticky:not(.c-compare-table__hugo) {
    left: 0;
}

// Hugo column, offset by width of copy column
th.c-compare-table__sticky.c-compare-table__hugo,
td.c-compare-table__sticky {
    left: 145px;

    @include at-min($bp-small) {
        left: 300px;
    }

    @include at-min($bp-medium) {
        left: 380px;
    }
}

.c-compare-table__container {
    // 1 visible, 4 clicks
    margin: 0 auto;
    max-width: 145px + 100px + 100px;

    @include at-min($bp-small) {
        max-width: 300px + 160px + 160px;
    }

    @include at-min($bp-medium) {
        max-width: 380px + 160px + 160px;
    }

    // 3 vsible, 2 click
    @include at-min($bp-large) {
        max-width: 380px + 160px + 160px + 160px + 160px;
    }
}

.c-compare-table__wrapper {
    $size: 8;

    --scrollbar-color: #dddddd;
    --scrollbar-bg: #{$color-white};

    @include dark-mode {
        --scrollbar-color: #{$color-dark-gray-95};
        --scrollbar-bg: #{$color-dark-gray-100};
    }

    overflow-y: hidden;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        height: $size * 1px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-color);
        border-radius: $space-2;
    }

    &::-webkit-scrollbar-track {
        background: var(--scrollbar-bg);
    }
}
