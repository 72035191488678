.c-plans__slider {
    margin: 0 auto;
    margin-bottom: $space-6;

    @include at-min($bp-small) {
        max-width: $bp-tiny * 1px;
    }
}

.c-plans__cta {
    margin: 0 auto;

    @include at-min($bp-small) {
        max-width: $bp-tiny * 1px;
    }
}

.c-plans__cards {
    margin-bottom: $space-8;

    @include at-max($bp-medium) {
        margin-left: auto;
        margin-right: auto;
        max-width: $bp-small * 1px;
    }

    @include at-min($bp-medium) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: $space-5;

        > * {
            flex: 0 0 30%;
        }
    }
}
