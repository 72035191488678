.l-media-list--truly-unique {
    @include at-max($bp-medium) {
        max-width: $bp-tiny * 1px;
        margin: 0 auto;
    }

    @include at-min($bp-medium) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        > * {
            width: 40%;
            margin-bottom: 0 !important;
        }
    }
}

.l-media-list--why-hugo {
    @include at-max($bp-medium) {
        max-width: $bp-tiny * 1px;
        margin: 0 auto;
    }

    @include at-min($bp-medium) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        > * {
            width: 40%;
        }
    }

    @include at-min($bp-large) {
        justify-content: space-between;
        > * {
            width: 30%;
        }
    }
}

.l-media-list--how-you-pay {
    min-height: 850px; // @NOTE magic number

    @include at-max($bp-medium) {
        max-width: $bp-tiny * 1px;
        margin: 0 auto;
    }

    @include at-min($bp-medium) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        > * {
            width: 30%;
        }
    }
}
