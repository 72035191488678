// Styles for "Team" section on About us page.

@use 'sass:math';

.c-team {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.c-team__member {
    text-align: center;
    margin-bottom: $space-6;
    padding: 0 $space-4;

    flex: 0 1 100%;

    @include at-min($bp-small) {
        flex-basis: percentage(math.div(6, 12));
    }

    @include at-min($bp-medium) {
        flex-basis: percentage(math.div(4, 12));
        margin-bottom: $space-8;
    }

    @include at-min($bp-large) {
        flex-basis: percentage(math.div(3, 12));
    }
}

.c-team__headshot {
    @include size(10rem);
    display: block;
    margin: 0 auto;
    margin-bottom: $space-8;
    border-radius: br('circle');
}
