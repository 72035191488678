.l-section {
    padding-top: $space-12;
    padding-bottom: $space-12;

    @include at-min($bp-small) {
        padding-top: $space-16;
        padding-bottom: $space-16;
    }

    &.is-full {
        min-height: calc(100svh - #{$header-height});
    }

    &:not(.is-full, .not-contained) {
        content-visibility: auto;
        contain: layout paint;
    }
}

.l-section--bottom {
    min-height: calc(100svh - #{$footer-height} - #{$header-height});
}
