.u-relative {
    position: relative !important;
}

.u-block {
    display: block !important;
}

.u-inline-block {
    display: inline-block !important;
}

.u-inline {
    display: inline !important;
}

.u-full-height {
    height: 100% !important;
}

.u-full-width {
    width: 100% !important;
}

.u-muted {
    opacity: 0.75 !important;
}

.u-circle {
    border-radius: br('circle') !important;
}

.u-pill {
    border-radius: br('pill') !important;
}

.u-no-wrap {
    white-space: nowrap !important;
}

.u-no-events {
    pointer-events: none !important;
}

.u-no-outline {
    outline: none !important;
}

.u-pointer {
    cursor: pointer !important;
}

.u-border-none {
    border: 0 !important;
}

.u-border-bottom {
    border-bottom: 1px solid currentcolor;
}

.u-border-top {
    border-top: 1px solid currentcolor;
}

.u-shadow-none {
    box-shadow: none !important;
}

.u-visually-hidden {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
}

.sm\:u-inline-block {
    @include at-min($bp-small) {
        display: inline-block !important;
    }
}
