@use 'sass:math';

$row-padding: $space-2;

.c-compare-plans {
    --bg-base: #{$color-white};
    --bg-alt: #{$color-purple-10};
    --border: #{$color-purple-10};

    @include dark-mode {
        --bg-base: #{$color-dark-gray-100};
        --bg-alt: #{$color-dark-gray-90};
        --border: #{$color-dark-gray-80};
    }

    border-radius: br('medium');
    overflow: hidden;

    @include at-max($bp-medium) {
        margin: 0 auto;
        max-width: $bp-small * 1px;
    }
}

.c-compare-plans__row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: $space-4;
    justify-content: space-between;

    &:nth-of-type(even) {
        background-color: var(--bg-alt);
    }

    @include at-min($bp-medium) {
        padding: 0;
        flex-wrap: nowrap;
        align-items: flex-start;

        &:last-child .c-compare-plans__icon {
            border-bottom: 1px solid var(--border);
            border-bottom-left-radius: br('medium');
            border-bottom-right-radius: br('medium');
        }
    }
}

.c-compare-plans__header {
    display: flex;
    justify-content: space-between;
    padding: $space-3 $space-4;

    @include at-min($bp-medium) {
        justify-content: flex-end;
        padding: 0;
    }

    > * {
        padding: 0 $space-3;
        display: flex;
        flex-direction: column;
        width: percentage(math.div(1, 3));
        align-items: center;
        text-align: center;

        @include at-min($bp-medium) {
            width: auto;
            font-weight: $font-bold;
            padding: $space-4 $space-2;

            &:not(.is-empty) {
                flex: 0 0 30%;
                border-left: 1px solid var(--border);
                border-right: 1px solid var(--border);
                border-top: 1px solid var(--border);
                border-top-left-radius: br('medium');
                border-top-right-radius: br('medium');
            }
        }

        @include at-min($bp-large) {
            font-size: $font-size-3;
            flex-direction: row;
            justify-content: center;
        }
    }

    img {
        margin-bottom: $space-2;

        @include at-min($bp-large) {
            margin-bottom: 0;
            margin-right: $space-2;
        }
    }

    // first row below header
    + .c-compare-plans__row {
        border-top-left-radius: br('medium');

        // border-top-right-radius: br("medium");
        overflow: hidden;
    }
}

.c-compare-plans__title {
    @include at-max($bp-medium) {
        width: percentage(math.div(1, 3));
        margin-bottom: $space-3;
    }

    @include at-min($bp-medium) {
        flex: 0 0 40%;
        padding: $space-4;
    }

    @include at-min($bp-large) {
        font-size: $font-size-3;
    }

    svg.is-expanded {
        transform: rotateX(-180deg);
    }
}

.c-compare-plans__icon {
    text-align: center;

    @include at-max($bp-medium) {
        flex: none;
        width: percentage(math.div(1, 3));
    }

    @include at-min($bp-medium) {
        flex: 0 0 30%;
        padding: $space-4;
        border-left: 1px solid var(--border);
        border-right: 1px solid var(--border);
    }
}

.c-compare-plans__details {
    max-height: 0;
    transition: max-height 250ms;
    overflow: hidden;
    will-change: max-height, height;

    &.is-expanded {
        max-height: 20rem;
    }

    > div {
        padding-top: $row-padding;
    }
}
