.c-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    user-select: none;
    text-decoration: none;
    text-align: center;
    border-radius: br('pill');
    transition: all 0.2s;

    font-size: $font-size-3;
    font-weight: $font-bold;
    line-height: $line-height-1;
    padding: $space-2 $space-4;

    &[disabled],
    &:disabled {
        @include disabled(0.3);
        filter: none !important;
    }

    &:focus {
        outline: none;
    }

    &:not(.c-btn--icon) {
        min-width: 8ch;
    }
}

.c-btn--primary {
    @include ripple($color-pink-20);
    color: $color-white;
    background: $gradient-base;
    overflow: hidden;

    &:not(.c-spinner):after {
        @include absolute(0 0 0 0);
        content: '';
        background: $gradient-dark;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: -2; // needs to be smaller than ripple z-index
        pointer-events: none;
    }

    &:hover,
    &:focus {
        color: $color-white;
        &:after {
            opacity: 1;
        }
    }

    &:focus {
        box-shadow: 0 0 1px 3px rgba($color-pink-90, 0.3);
    }

    &.c-spinner:after {
        border-top-color: $color-pink-90;
    }
}

.c-btn--secondary {
    @include ripple($color-purple-80);
    color: $color-white;
    background: $gradient-alt;
    overflow: hidden;

    @include dark-mode {
        background: $color-purple-90;
    }

    &:not(.c-spinner):after {
        @include absolute(0 0 0 0);
        content: '';
        background: $color-dark-purple-03;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: -2; // needs to be smaller than ripple z-index
        pointer-events: none;

        @include dark-mode {
            background: $color-dark-purple-02;
        }
    }

    &:hover,
    &:focus {
        color: $color-white;
        &:after {
            opacity: 1;
        }
    }

    &:focus {
        box-shadow: 0 0 1px 3px rgba($color-purple-100, 0.3);
    }

    &.c-spinner:after {
        border-top-color: $color-dark-purple-03;
    }
}

.c-btn--inverted {
    @include ripple($color-purple-80);
    color: $color-purple-100;
    background-color: $color-white;

    &:hover,
    &:focus {
        color: $color-dark-purple-03;
        background-color: $color-purple-10;
    }

    &:focus {
        box-shadow: 0 0 1px 3px rgba($color-white, 0.3);
    }

    &.c-spinner:after {
        border-top-color: $color-dark-purple-03;
    }
}

.c-btn--outline {
    background: $color-white !important; // @TODO: remove important
    box-shadow: inset 0 0 0 2px currentcolor;

    @include dark-mode {
        background: transparent !important;
    }

    &:after {
        display: none;
    }

    &.c-btn--primary {
        color: $color-pink-100;

        &:hover,
        &:focus {
            color: $color-dark-pink-01;
        }

        @include dark-mode {
            color: $color-pink-30;

            &:hover,
            &:focus {
                color: $color-white;
            }
        }

        &:focus {
            box-shadow:
                inset 0 0 0 2px currentcolor,
                0 0 1px 3px rgba($color-pink-100, 0.3);
        }
    }

    &.c-btn--secondary {
        color: $color-purple-100;

        &:hover,
        &:focus {
            color: $color-dark-purple-03;
        }

        @include dark-mode {
            color: $color-purple-30;

            &:hover,
            &:focus {
                color: $color-white;
            }
        }

        &:focus {
            box-shadow:
                inset 0 0 0 2px currentcolor,
                0 0 1px 3px rgba($color-purple-100, 0.3);
        }
    }

    &.c-btn--inverted {
        @include ripple($color-white);
        color: $color-white;

        &:hover,
        &:focus {
            color: $color-purple-05;
        }

        &:focus {
            box-shadow:
                inset 0 0 0 2px currentcolor,
                0 0 1px 3px rgba($color-white, 0.3);
        }
    }
}

.c-btn--block {
    width: 100%;
    display: block;
}

.c-btn--large {
    min-height: $space-12;
}

a.c-btn--large {
    padding-top: $space-4;
    padding-bottom: $space-4;
}

.c-btn--raised {
    filter: drop-shadow(#{$shadow-200});

    &.c-btn--large {
        filter: drop-shadow(#{$shadow-300});
    }
}

.c-btn--icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: $space-2;

    &:not(.c-link) > * {
        margin: 0 $space-1;
    }

    > * + * {
        margin-left: 0;
    }

    > *:first-child:last-child {
        margin: 0;
    }

    svg {
        flex: none;
    }

    &.c-btn--block {
        padding-left: $space-6;
        padding-right: $space-6;

        svg {
            margin: 0;
        }

        > *:not(svg) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.c-btn--help {
    @include ripple($color-purple-80);

    @include at-max($bp-medium) {
        filter: drop-shadow(#{$shadow-500});

        &:hover,
        &:focus {
            filter: drop-shadow(0 0 2px rgba($color-black, 0.2));
        }
    }

    @include at-min($bp-medium) {
        background: $color-white;
        padding: $space-1;

        &:hover,
        &:focus {
            background: $color-gray-10;
        }

        @include dark-mode {
            background-color: $color-dark-gray-90;

            &:hover,
            &:focus {
                background: $color-dark-gray-80;
            }
        }
    }
}

@keyframes floating {
    0%,
    100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10%);
    }
}

.c-btn--cta {
    font-size: $font-size-5;
    padding-left: $space-6;
    padding-right: $space-6;
    box-shadow: $shadow-300;

    &:not(:hover),
    &:not(:focus) {
        svg {
            animation: floating 1.3s infinite ease-out;
        }
    }
}

.c-btn--menu {
    @include ripple($color-purple-100);
    font-size: $font-size-4;
    color: $color-pink-100;

    &.is-open {
        color: $color-purple-100;
        background: $color-purple-10 !important;
    }

    @include dark-mode {
        color: $color-pink-30;

        &.is-open {
            color: $color-white;
            background: $color-dark-gray-80 !important;
        }
    }
}
