// Flexbox utility classes.
// https://css-tricks.com/snippets/css/a-guide-to-flexbox/

.u-flex {
    display: flex !important;
}

.u-inline-flex {
    display: inline-flex !important;
}

.u-flex-column {
    flex-direction: column !important;
}

.u-flex-wrap {
    flex-wrap: wrap !important;
}

.u-justify-around {
    justify-content: space-around !important;
}

.u-justify-between {
    justify-content: space-between !important;
}

.u-justify-center {
    justify-content: center !important;
}

.u-justify-end {
    justify-content: flex-end !important;
}

.u-justify-evenly {
    justify-content: space-evenly !important;
}

.u-justify-start {
    justify-content: flex-start !important;
}

.u-items-baseline {
    align-items: baseline !important;
}

.u-items-center {
    align-items: center !important;
}

.u-items-end {
    align-items: flex-end !important;
}

.u-items-start {
    align-items: flex-start !important;
}

.u-items-stretch {
    align-items: stretch !important;
}

.u-content-around {
    align-content: space-around !important;
}

.u-content-between {
    align-content: space-between !important;
}

.u-content-center {
    align-content: center !important;
}

.u-content-end {
    align-content: flex-end !important;
}

.u-content-start {
    align-content: flex-start !important;
}

.u-content-stretch {
    align-content: stretch !important;
}

.u-self-start {
    align-self: flex-start !important;
}

.u-self-end {
    align-self: flex-end !important;
}

.u-self-center {
    align-self: center !important;
}

.u-self-baseline {
    align-self: baseline !important;
}

.u-self-stretch {
    align-items: stretch !important;
}

// Setting 0% instead of auto for flex-basis insures that
// multiple flex elements with this class all get equal width.
.u-flex-auto {
    flex: 1 1 0% !important;
}

.u-flex-shrink {
    flex: 0 1 auto !important;
} // initial value
.u-flex-grow {
    flex: 1 0 auto !important;
}

.u-flex-none {
    flex: none !important;
}

.u-order-none {
    order: 0 !important;
} // initial value
.u-order-1 {
    order: 1 !important;
}

.u-order-2 {
    order: 2 !important;
}

.u-order-3 {
    order: 3 !important;
}

.u-order-4 {
    order: 4 !important;
}

.u-order-first {
    order: -99 !important;
}

.u-order-last {
    order: 99 !important;
}

.u-flex-center {
    display: flex !important;
    > * {
        margin: auto !important;
    }
}

.sm\:u-flex {
    @include at-min($bp-small) {
        display: flex !important;
    }
}

.md\:u-flex {
    @include at-min($bp-medium) {
        display: flex !important;
    }
}

.lg\:u-flex {
    @include at-min($bp-large) {
        display: flex !important;
    }
}
