.c-quote {
    background-color: $color-white;
    padding: $space-4;
    border-radius: br('medium');
    box-shadow: $shadow-25;

    @include dark-mode {
        background-color: $color-dark-gray-100;
    }
}

.c-quote__quote {
    display: block;
    font-size: $font-size-3;
    line-height: $line-height-3;
    margin-bottom: $space-2;

    quotes: '\201C' '\201D';
    &:before {
        content: open-quote;
    }
    &:after {
        content: close-quote;
    }
}

.c-quote__footer {
    font-size: $font-size-2;
}

.c-quote__cite {
    text-transform: uppercase;
}
