.l-container {
    width: 100%;
    max-width: $bp-large * 1px;
    margin: 0 auto;
    padding: 0 $space-3;

    @include at-min($bp-medium) {
        padding: 0 ($space-1 * 9);
    }
}

.l-container--small {
    max-width: $bp-tiny * 1px;
}

.l-container--medium {
    @include main-content(70);
}
