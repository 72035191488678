.c-trustpilot {
    display: grid;
    min-height: $trustpilot-height;
    align-content: center;

    @include at-max($bp-medium) {
        text-align: center;
    }

    @include at-min($bp-medium) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-template-rows: max-content;
        row-gap: 0;
        column-gap: $space-3;
    }

    @include at-min($bp-large) {
        column-gap: $space-6;
    }
}

.c-trustpilot__copy {
    grid-column: 1 / span 3;
}

.c-trustpilot__link {
    @include at-max($bp-large) {
        order: 99;
    }
}

.c-trustpilot__title {
    font-size: $font-size-8;
    font-weight: $font-bold;
    line-height: $line-height-1;
    margin-top: $space-2;
    margin-bottom: $space-4;

    @include at-min($bp-medium) {
        margin: $space-1 0;
    }
}

.c-trustpilot__stars {
    margin-bottom: $space-4;
    grid-column: 1 / span 3;

    @include at-min($bp-medium) {
        grid-column: 1 / span 4;
        margin-bottom: $space-2;
    }

    @include at-min($bp-large) {
        grid-column: 4 / span 4;
        grid-row: 1 / 99;
    }
}

.c-trustpilot__quotes {
    grid-column: 6 / span 5;
    grid-row: 1 / 99;
    min-height: 160px;

    @include at-min($bp-large) {
        margin-top: -30px;
        grid-column: 8 / span 6;
    }

    position: relative;

    blockquote {
        @include absolute(0 0 0 0);
        @include fade(6, 1, 4);
        margin: 0 auto;
    }
}
