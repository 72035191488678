.l-square {
    display: inline-block;
}

.l-square__inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: '';
        padding-top: 100%;
    }
}

.l-square--fixed {
    width: 1.8em;

    .l-square__inner {
        justify-content: center;
    }
}
