@function circle($color) {
    $shape: '<svg width="375" height="53" viewBox="0 0 375 53" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M375 0H0V52.2933C54.4581 18.7812 122.149 2 203.945 2C253.826 2 317.48 12.8438 375 47.8501V0Z" fill="' +
        $color + '"/></svg>';
    @return svg-url($shape);
}

@function wave-top($color) {
    $shape: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 375 22"><path fill="' +
        $color +
        '" fill-rule="evenodd" d="M254.855 0H0v18.555c42.828 5.625 89.104-.88 137.531-7.687C175.524 5.528 214.841.002 254.855 0Zm.036 0C294.447.002 334.685 5.404 375 21.723V0H254.891Z" clip-rule="evenodd"/></svg>';
    @return svg-url($shape);
}

@function wave-bottom($color) {
    $shape: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1440 68"><path fill="' +
        $color +
        '" fill-rule="evenodd" d="M1440 68V43.431c-.71-.142-1.41-.286-2.12-.431-361.67-74.332-664.271-36.042-918.027-3.931C317.142 64.719 145.598 86.427 0 43.628V68h1440Z" clip-rule="evenodd"/></svg>';
    @return svg-url($shape);
}

.c-bg-shape--hero {
    position: relative;

    &:before {
        @include absolute(null 0 0 0);
        content: '';
        z-index: -1;
        user-select: none;
        pointer-events: none;
        background-image: circle($color-white), $gradient-bg-1;
        background-position: top, center;
        background-size:
            100% auto,
            cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 46%;

        @include dark-mode {
            background-image: circle($color-dark-gray-100), $gradient-bg-5;
        }

        @include at-min($bp-small) {
            height: 62%;
        }

        @include at-min($bp-medium) {
            @include absolute(20% -50% auto auto);
            background: $gradient-bg-1;
            background-size: auto auto;
            width: 100%;
            height: 0;
            padding-top: 100%;
            border-radius: 50%;

            @include dark-mode {
                background: $gradient-bg-5;
            }
        }
    }
}

.c-bg-shape--wave {
    position: relative;
    background: $gradient-bg-2;
    padding-bottom: $space-14;
    margin-bottom: $space-14;

    @include dark-mode {
        background: $color-dark-gray-100;
        margin-bottom: 0;
    }

    &:after {
        @include absolute(0 0 -1px 0);
        content: '';
        user-select: none;
        pointer-events: none;
        background-image: wave-bottom($color-white);
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 101% auto;

        @include dark-mode {
            background-image: wave-bottom($color-dark-purple-02);
        }
    }

    &.has-alt-dark-mode {
        @include dark-mode {
            background: $gradient-bg-6;
            &:after {
                background-image: wave-bottom($color-dark-gray-100);
            }
        }
    }
}

.c-bg-shape--plans {
    background: $gradient-bg-3;

    @include dark-mode {
        background: $color-dark-purple-02;
    }
}

.c-bg-shape--wave-2 {
    position: relative;
    background: $gradient-bg-4;
    padding-top: $space-12;
    padding-bottom: $space-12;
    margin-top: $space-10;

    &:before {
        @include absolute(0 0 0 0);
        content: '';
        user-select: none;
        pointer-events: none;
        background-image: wave-top($color-white);
        background-repeat: no-repeat;
        background-position: top left;
    }

    @include dark-mode {
        background: $color-dark-purple-02;
        &:before {
            background-image: wave-top($color-dark-gray-100);
        }
        &:after {
            background-image: wave-bottom($color-dark-gray-100);
        }
    }

    &.has-bottom {
        padding-top: $space-14;
        padding-bottom: $space-14;
        margin-top: 0;

        &:after {
            @include absolute(0 0 -1px 0);
            content: '';
            user-select: none;
            pointer-events: none;
            background-image: wave-bottom($color-white);
            background-repeat: no-repeat;
            background-position: bottom left;
        }

        @include dark-mode {
            &:after {
                background-image: wave-bottom($color-dark-gray-100);
            }
        }
    }

    &.has-gray-top-color {
        &:before {
            background-image: wave-top($color-gray-05);
        }

        @include dark-mode {
            &:before {
                background-image: wave-top($color-dark-gray-95);
            }
        }
    }
}
