.c-hero {
    margin: 0 auto;

    @include at-max($bp-medium) {
        max-width: $mobile-hero-width;
    }

    @include at-min($bp-medium) {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        column-gap: $space-3;
        align-items: center;
    }

    @include at-min($bp-large) {
        column-gap: $space-6;
    }

    & sup {
        font-size: 0.6em;
    }
}

.c-hero__copy {
    max-width: $bp-tiny * 1px;
    text-align: center;

    @include at-min($bp-medium) {
        // max-width: none;
        text-align: left;
        grid-column: 1 / span 6;
    }
}

.c-hero__title {
    line-height: $line-height-1;
    font-size: $font-size-9;
    margin-bottom: $space-3;

    @include at-min($bp-medium) {
        margin-bottom: $space-4;
    }

    @include at-min($bp-medium) {
        line-height: 1;
        font-size: $font-size-11;
    }
}

.c-hero__text {
    font-size: $font-size-3;
    line-height: $line-height-3;
    margin-bottom: $space-6;

    @include at-min($bp-medium) {
        font-size: $font-size-6;
        margin-bottom: $space-10;
    }
}

.c-hero__cta {
    @include at-max($bp-medium) {
        margin: auto $space-2;
    }

    @include at-min($bp-medium) {
        max-width: $hero-cta-width;
    }
}

.c-hero__cta-subtitle {
    margin-top: $space-3;
}

.c-hero__video {
    grid-column: 8 / span 6;

    @include at-max($bp-medium) {
        margin: $space-10 auto 0;
        text-align: center;
    }
}
