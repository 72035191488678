
.u-txt-white                { color: $color-white !important; }

.u-bg-white                 { background-color: $color-white !important; }
.u-bg-none                  { background-color: transparent !important; }

.u-txt-gray-100             { color: $color-gray-100 !important; }
.u-txt-gray-80              { color: $color-gray-80 !important; }
.u-txt-gray-50              { color: $color-gray-50 !important; }
.u-txt-gray-30              { color: $color-gray-30 !important; }
.u-txt-gray-20              { color: $color-gray-20 !important; }
.u-txt-gray-10              { color: $color-gray-10 !important; }
.u-txt-gray-05              { color: $color-gray-05 !important; }

.u-txt-purple-100           { color: $color-purple-100 !important; }
.u-txt-purple-90            { color: $color-purple-90 !important; }
.u-txt-purple-80            { color: $color-purple-80 !important; }
.u-txt-purple-70            { color: $color-purple-70 !important; }
.u-txt-purple-60            { color: $color-purple-60 !important; }
.u-txt-purple-50            { color: $color-purple-50 !important; }
.u-txt-purple-40            { color: $color-purple-40 !important; }
.u-txt-purple-30            { color: $color-purple-30 !important; }
.u-txt-purple-20            { color: $color-purple-20 !important; }
.u-txt-purple-10            { color: $color-purple-10 !important; }
.u-txt-purple-05            { color: $color-purple-05 !important; }

.u-txt-pink-100             { color: $color-pink-100 !important; }
.u-txt-pink-90              { color: $color-pink-90 !important; }
.u-txt-pink-80              { color: $color-pink-80 !important; }
.u-txt-pink-70              { color: $color-pink-70 !important; }
.u-txt-pink-60              { color: $color-pink-60 !important; }
.u-txt-pink-50              { color: $color-pink-50 !important; }
.u-txt-pink-40              { color: $color-pink-40 !important; }
.u-txt-pink-30              { color: $color-pink-30 !important; }
.u-txt-pink-20              { color: $color-pink-20 !important; }
.u-txt-pink-10              { color: $color-pink-10 !important; }
.u-txt-pink-05              { color: $color-pink-05 !important; }

.u-txt-dark-gray-100        { color: $color-dark-gray-100 !important; }
.u-txt-dark-gray-95         { color: $color-dark-gray-95 !important; }
.u-txt-dark-gray-90         { color: $color-dark-gray-90 !important; }
.u-txt-dark-gray-80         { color: $color-dark-gray-80 !important; }
.u-txt-dark-gray-70         { color: $color-dark-gray-70 !important; }
.u-txt-dark-gray-60         { color: $color-dark-gray-60 !important; }
.u-txt-dark-gray-50         { color: $color-dark-gray-50 !important; }
.u-txt-dark-gray-40         { color: $color-dark-gray-40 !important; }
.u-txt-dark-gray-30         { color: $color-dark-gray-30 !important; }
.u-txt-dark-gray-20         { color: $color-dark-gray-20 !important; }
.u-txt-dark-gray-10         { color: $color-dark-gray-10 !important; }
.u-txt-dark-gray-05         { color: $color-dark-gray-05 !important; }

.u-txt-dark-purple-02       { color: $color-dark-purple-02 !important; }
.u-txt-dark-purple-03       { color: $color-dark-purple-03 !important; }
.u-txt-dark-purple-01       { color: $color-dark-purple-01 !important; }

.u-txt-dark-pink-01         { color: $color-dark-pink-01 !important; }
.u-txt-dark-pink-02         { color: $color-dark-pink-02 !important; }
.u-txt-dark-pink-03         { color: $color-dark-pink-03 !important; }

.u-txt-green-01             { color: $color-green-01 !important; }
.u-txt-green-02             { color: $color-green-02 !important; }
.u-txt-green-03             { color: $color-green-03 !important; }

.u-txt-yellow-01            { color: $color-yellow-01 !important; }

.u-txt-red-01               { color: $color-red-01 !important; }
.u-txt-red-02               { color: $color-red-02 !important; }

///////////////////////////////////////////////////////////////////////

.u-bg-gray-100              { background-color: $color-gray-100 !important; }
.u-bg-gray-80               { background-color: $color-gray-80 !important; }
.u-bg-gray-50               { background-color: $color-gray-50 !important; }
.u-bg-gray-30               { background-color: $color-gray-30 !important; }
.u-bg-gray-20               { background-color: $color-gray-20 !important; }
.u-bg-gray-10               { background-color: $color-gray-10 !important; }
.u-bg-gray-05               { background-color: $color-gray-05 !important; }

.u-bg-purple-100           { background-color: $color-purple-100 !important; }
.u-bg-purple-90            { background-color: $color-purple-90 !important; }
.u-bg-purple-80            { background-color: $color-purple-80 !important; }
.u-bg-purple-70            { background-color: $color-purple-70 !important; }
.u-bg-purple-60            { background-color: $color-purple-60 !important; }
.u-bg-purple-50            { background-color: $color-purple-50 !important; }
.u-bg-purple-40            { background-color: $color-purple-40 !important; }
.u-bg-purple-30            { background-color: $color-purple-30 !important; }
.u-bg-purple-20            { background-color: $color-purple-20 !important; }
.u-bg-purple-10            { background-color: $color-purple-10 !important; }
.u-bg-purple-05            { background-color: $color-purple-05 !important; }

.u-bg-pink-100             { background-color: $color-pink-100 !important; }
.u-bg-pink-90              { background-color: $color-pink-90 !important; }
.u-bg-pink-80              { background-color: $color-pink-80 !important; }
.u-bg-pink-70              { background-color: $color-pink-70 !important; }
.u-bg-pink-60              { background-color: $color-pink-60 !important; }
.u-bg-pink-50              { background-color: $color-pink-50 !important; }
.u-bg-pink-40              { background-color: $color-pink-40 !important; }
.u-bg-pink-30              { background-color: $color-pink-30 !important; }
.u-bg-pink-20              { background-color: $color-pink-20 !important; }
.u-bg-pink-10              { background-color: $color-pink-10 !important; }
.u-bg-pink-05              { background-color: $color-pink-05 !important; }

.u-bg-dark-gray-100        { background-color: $color-dark-gray-100 !important; }
.u-bg-dark-gray-95         { background-color: $color-dark-gray-95 !important; }
.u-bg-dark-gray-90         { background-color: $color-dark-gray-90 !important; }
.u-bg-dark-gray-80         { background-color: $color-dark-gray-80 !important; }
.u-bg-dark-gray-70         { background-color: $color-dark-gray-70 !important; }
.u-bg-dark-gray-60         { background-color: $color-dark-gray-60 !important; }
.u-bg-dark-gray-50         { background-color: $color-dark-gray-50 !important; }
.u-bg-dark-gray-40         { background-color: $color-dark-gray-40 !important; }
.u-bg-dark-gray-30         { background-color: $color-dark-gray-30 !important; }
.u-bg-dark-gray-20         { background-color: $color-dark-gray-20 !important; }
.u-bg-dark-gray-10         { background-color: $color-dark-gray-10 !important; }
.u-bg-dark-gray-05         { background-color: $color-dark-gray-05 !important; }

.u-bg-dark-purple-02       { background-color: $color-dark-purple-02 !important; }
.u-bg-dark-purple-03       { background-color: $color-dark-purple-03 !important; }
.u-bg-dark-purple-01       { background-color: $color-dark-purple-01 !important; }

.u-bg-dark-pink-01         { background-color: $color-dark-pink-01 !important; }
.u-bg-dark-pink-02         { background-color: $color-dark-pink-02 !important; }
.u-bg-dark-pink-03         { background-color: $color-dark-pink-03 !important; }

.u-bg-green-01             { background-color: $color-green-01 !important; }
.u-bg-green-02             { background-color: $color-green-02 !important; }
.u-bg-green-03             { background-color: $color-green-03 !important; }

.u-bg-yellow-01            { background-color: $color-yellow-01 !important; }

.u-bg-red-01               { background-color: $color-red-01 !important; }
.u-bg-red-02               { background-color: $color-red-02 !important; }


@include dark-mode {
    .dark\:u-txt-white                { color: $color-white !important; }

    .dark\:u-bg-white                 { background-color: $color-white !important; }
    .dark\:u-bg-none                  { background-color: transparent !important; }

    .dark\:u-txt-gray-100             { color: $color-gray-100 !important; }
    .dark\:u-txt-gray-80              { color: $color-gray-80 !important; }
    .dark\:u-txt-gray-50              { color: $color-gray-50 !important; }
    .dark\:u-txt-gray-30              { color: $color-gray-30 !important; }
    .dark\:u-txt-gray-20              { color: $color-gray-20 !important; }
    .dark\:u-txt-gray-10              { color: $color-gray-10 !important; }
    .dark\:u-txt-gray-05              { color: $color-gray-05 !important; }

    .dark\:u-txt-purple-100           { color: $color-purple-100 !important; }
    .dark\:u-txt-purple-90            { color: $color-purple-90 !important; }
    .dark\:u-txt-purple-80            { color: $color-purple-80 !important; }
    .dark\:u-txt-purple-70            { color: $color-purple-70 !important; }
    .dark\:u-txt-purple-60            { color: $color-purple-60 !important; }
    .dark\:u-txt-purple-50            { color: $color-purple-50 !important; }
    .dark\:u-txt-purple-40            { color: $color-purple-40 !important; }
    .dark\:u-txt-purple-30            { color: $color-purple-30 !important; }
    .dark\:u-txt-purple-20            { color: $color-purple-20 !important; }
    .dark\:u-txt-purple-10            { color: $color-purple-10 !important; }
    .dark\:u-txt-purple-05            { color: $color-purple-05 !important; }

    .dark\:u-txt-pink-100             { color: $color-pink-100 !important; }
    .dark\:u-txt-pink-90              { color: $color-pink-90 !important; }
    .dark\:u-txt-pink-80              { color: $color-pink-80 !important; }
    .dark\:u-txt-pink-70              { color: $color-pink-70 !important; }
    .dark\:u-txt-pink-60              { color: $color-pink-60 !important; }
    .dark\:u-txt-pink-50              { color: $color-pink-50 !important; }
    .dark\:u-txt-pink-40              { color: $color-pink-40 !important; }
    .dark\:u-txt-pink-30              { color: $color-pink-30 !important; }
    .dark\:u-txt-pink-20              { color: $color-pink-20 !important; }
    .dark\:u-txt-pink-10              { color: $color-pink-10 !important; }
    .dark\:u-txt-pink-05              { color: $color-pink-05 !important; }

    .dark\:u-txt-dark-gray-100        { color: $color-dark-gray-100 !important; }
    .dark\:u-txt-dark-gray-95         { color: $color-dark-gray-95 !important; }
    .dark\:u-txt-dark-gray-90         { color: $color-dark-gray-90 !important; }
    .dark\:u-txt-dark-gray-80         { color: $color-dark-gray-80 !important; }
    .dark\:u-txt-dark-gray-70         { color: $color-dark-gray-70 !important; }
    .dark\:u-txt-dark-gray-60         { color: $color-dark-gray-60 !important; }
    .dark\:u-txt-dark-gray-50         { color: $color-dark-gray-50 !important; }
    .dark\:u-txt-dark-gray-40         { color: $color-dark-gray-40 !important; }
    .dark\:u-txt-dark-gray-30         { color: $color-dark-gray-30 !important; }
    .dark\:u-txt-dark-gray-20         { color: $color-dark-gray-20 !important; }
    .dark\:u-txt-dark-gray-10         { color: $color-dark-gray-10 !important; }
    .dark\:u-txt-dark-gray-05         { color: $color-dark-gray-05 !important; }

    .dark\:u-txt-dark-purple-01       { color: $color-dark-purple-01 !important; }
    .dark\:u-txt-dark-purple-02       { color: $color-dark-purple-02 !important; }
    .dark\:u-txt-dark-purple-03       { color: $color-dark-purple-03 !important; }

    .dark\:u-txt-dark-pink-01         { color: $color-dark-pink-01 !important; }
    .dark\:u-txt-dark-pink-02         { color: $color-dark-pink-02 !important; }
    .dark\:u-txt-dark-pink-03         { color: $color-dark-pink-03 !important; }

    .dark\:u-txt-green-01             { color: $color-green-01 !important; }
    .dark\:u-txt-green-02             { color: $color-green-02 !important; }
    .dark\:u-txt-green-03             { color: $color-green-03 !important; }

    .dark\:u-txt-yellow-01            { color: $color-yellow-01 !important; }

    .dark\:u-txt-red-01               { color: $color-red-01 !important; }
    .dark\:u-txt-red-02               { color: $color-red-02 !important; }

    ///////////////////////////////////////////////////////////////////////

    .dark\:u-bg-gray-100              { background-color: $color-gray-100 !important; }
    .dark\:u-bg-gray-80               { background-color: $color-gray-80 !important; }
    .dark\:u-bg-gray-50               { background-color: $color-gray-50 !important; }
    .dark\:u-bg-gray-30               { background-color: $color-gray-30 !important; }
    .dark\:u-bg-gray-20               { background-color: $color-gray-20 !important; }
    .dark\:u-bg-gray-10               { background-color: $color-gray-10 !important; }
    .dark\:u-bg-gray-05               { background-color: $color-gray-05 !important; }

    .dark\:u-bg-purple-100           { background-color: $color-purple-100 !important; }
    .dark\:u-bg-purple-90            { background-color: $color-purple-90 !important; }
    .dark\:u-bg-purple-80            { background-color: $color-purple-80 !important; }
    .dark\:u-bg-purple-70            { background-color: $color-purple-70 !important; }
    .dark\:u-bg-purple-60            { background-color: $color-purple-60 !important; }
    .dark\:u-bg-purple-50            { background-color: $color-purple-50 !important; }
    .dark\:u-bg-purple-40            { background-color: $color-purple-40 !important; }
    .dark\:u-bg-purple-30            { background-color: $color-purple-30 !important; }
    .dark\:u-bg-purple-20            { background-color: $color-purple-20 !important; }
    .dark\:u-bg-purple-10            { background-color: $color-purple-10 !important; }
    .dark\:u-bg-purple-05            { background-color: $color-purple-05 !important; }

    .dark\:u-bg-pink-100             { background-color: $color-pink-100 !important; }
    .dark\:u-bg-pink-90              { background-color: $color-pink-90 !important; }
    .dark\:u-bg-pink-80              { background-color: $color-pink-80 !important; }
    .dark\:u-bg-pink-70              { background-color: $color-pink-70 !important; }
    .dark\:u-bg-pink-60              { background-color: $color-pink-60 !important; }
    .dark\:u-bg-pink-50              { background-color: $color-pink-50 !important; }
    .dark\:u-bg-pink-40              { background-color: $color-pink-40 !important; }
    .dark\:u-bg-pink-30              { background-color: $color-pink-30 !important; }
    .dark\:u-bg-pink-20              { background-color: $color-pink-20 !important; }
    .dark\:u-bg-pink-10              { background-color: $color-pink-10 !important; }
    .dark\:u-bg-pink-05              { background-color: $color-pink-05 !important; }

    .dark\:u-bg-dark-gray-100        { background-color: $color-dark-gray-100 !important; }
    .dark\:u-bg-dark-gray-95         { background-color: $color-dark-gray-95 !important; }
    .dark\:u-bg-dark-gray-90         { background-color: $color-dark-gray-90 !important; }
    .dark\:u-bg-dark-gray-80         { background-color: $color-dark-gray-80 !important; }
    .dark\:u-bg-dark-gray-70         { background-color: $color-dark-gray-70 !important; }
    .dark\:u-bg-dark-gray-60         { background-color: $color-dark-gray-60 !important; }
    .dark\:u-bg-dark-gray-50         { background-color: $color-dark-gray-50 !important; }
    .dark\:u-bg-dark-gray-40         { background-color: $color-dark-gray-40 !important; }
    .dark\:u-bg-dark-gray-30         { background-color: $color-dark-gray-30 !important; }
    .dark\:u-bg-dark-gray-20         { background-color: $color-dark-gray-20 !important; }
    .dark\:u-bg-dark-gray-10         { background-color: $color-dark-gray-10 !important; }
    .dark\:u-bg-dark-gray-05         { background-color: $color-dark-gray-05 !important; }

    .dark\:u-bg-dark-purple-01       { background-color: $color-dark-purple-01 !important; }
    .dark\:u-bg-dark-purple-02       { background-color: $color-dark-purple-02 !important; }
    .dark\:u-bg-dark-purple-03       { background-color: $color-dark-purple-03 !important; }

    .dark\:u-bg-dark-pink-01         { background-color: $color-dark-pink-01 !important; }
    .dark\:u-bg-dark-pink-02         { background-color: $color-dark-pink-02 !important; }
    .dark\:u-bg-dark-pink-03         { background-color: $color-dark-pink-03 !important; }

    .dark\:u-bg-green-01             { background-color: $color-green-01 !important; }
    .dark\:u-bg-green-02             { background-color: $color-green-02 !important; }
    .dark\:u-bg-green-03             { background-color: $color-green-03 !important; }

    .dark\:u-bg-yellow-01            { background-color: $color-yellow-01 !important; }

    .dark\:u-bg-red-01               { background-color: $color-red-01 !important; }
    .dark\:u-bg-red-02               { background-color: $color-red-02 !important; }
}
