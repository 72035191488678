// currently available
$available: 'AL', 'AR', 'AZ', 'CA', 'FL', 'GA', 'IL', 'IN', 'LA', 'MS', 'OH', 'PA', 'SC', 'TN', 'TX',
    'VA';

// flex, basic, full
$all: 'PLACEHOLDER';

// basic, full
// $unlimited: 'PLACEHOLDER';

$basic: 'AL', 'AZ', 'FL', 'GA', 'IN', 'MS', 'OH', 'PA', 'SC', 'TN', 'TX';

// launching soon
$building: '';

// work in progress
$planning: 'KY', 'CA';

@keyframes state {
    0%,
    100% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.01, 1.01);
    }
}

.c-map {
    position: relative;
    margin-bottom: $space-8;
}

.c-map__svg {
    display: block;
    width: 100%;
    height: 100%;

    path {
        stroke: $color-white;
        fill: $color-pink-30;
        transform-origin: center center;
    }

    @each $state in $available {
        [id='#{$state}'] {
            fill: $color-purple-100;
        }
    }

    @each $state in $building {
        [id='#{$state}'] {
            fill: $color-purple-50;
        }
    }

    @each $state in $planning {
        [id='#{$state}'] {
            fill: $color-pink-50;
        }
    }

    path:hover {
        cursor: pointer;
        animation: state 300ms ease forwards;
    }
}

// .c-map__svg--alt {
//     @each $state in $all {
//         [id='#{$state}'] {
//             fill: $color-purple-100;
//         }
//     }

//     @each $state in $unlimited {
//         [id='#{$state}'] {
//             fill: $color-pink-90;
//         }
//     }

//     @each $state in $basic {
//         [id='#{$state}'] {
//             fill: $color-pink-50;
//         }
//     }

//     @each $state in $building {
//         [id='#{$state}'] {
//             fill:  $color-purple-50;
//         }
//     }

//     @each $state in $planning {
//         [id='#{$state}'] {
//             fill:  $color-purple-50;
//         }
//     }
// }

.c-map__tooltip {
    opacity: 0;
    padding: $space-2;
    border-radius: br('small');
    position: fixed;
    background-color: $color-white;
    border: 1px solid $color-purple-100;
    width: fit-content;
    transform: translateX(-50%);
    transition: opacity 0.4s ease;
    pointer-events: none;
    z-index: 1;

    @include dark-mode {
        color: $color-white;
        background-color: $color-purple-100;
        border-color: $color-white;
    }
}

.c-map__legend {
    text-align: center;

    ul {
        display: inline-block;
    }

    li {
        margin-bottom: $space-2;
        display: flex;
        align-items: flex-start;
        text-align: left;

        @include at-min($bp-medium) {
            margin-bottom: $space-3;
        }

        &:before {
            @include size($space-4);
            content: '';
            display: block;
            flex: none;
            margin-right: $space-2;
        }

        &.is-available:before {
            background-color: $color-purple-100;
        }

        &.is-building:before {
            background-color: $color-purple-50;
        }

        &.is-planning:before {
            background-color: $color-pink-50;
        }

        &.is-waitlist:before {
            background-color: $color-pink-30;
        }

        &.is-all:before {
            background-color: $color-purple-100;
        }

        &.is-unlimited:before {
            background-color: $color-pink-90;
        }

        &.is-basic:before {
            background-color: $color-pink-50;
        }

        &.is-launching:before {
            background-color: $color-purple-50;
        }
    }
}

.c-map__wrapper {
    @include at-min($bp-large) {
        display: flex;
        align-items: center;

        .c-map {
            flex: 1 1 80%;
        }

        .c-map__svg {
            margin-left: negative($space-4);
        }
    }
}
