@use 'sass:math';

@font-face {
    font-family: gilroy-webfont;
    src:
        url('https://static.withhugo.com/fonts/gilroy/gilroy-light-webfont.woff2') format('woff2'),
        url('https://static.withhugo.com/fonts/gilroy/gilroy-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: gilroy-webfont;
    src:
        url('https://static.withhugo.com/fonts/gilroy/gilroy-regular-webfont.woff2') format('woff2'),
        url('https://static.withhugo.com/fonts/gilroy/gilroy-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: gilroy-webfont;
    src:
        url('https://static.withhugo.com/fonts/gilroy/gilroy-medium-webfont.woff2') format('woff2'),
        url('https://static.withhugo.com/fonts/gilroy/gilroy-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Gilroy extra bold should fallback to sans-serif bold so we define weight at 700 instead of 900. */
@font-face {
    font-family: gilroy-webfont;
    src:
        url('https://static.withhugo.com/fonts/gilroy/gilroy-extrabold-webfont.woff2')
            format('woff2'),
        url('https://static.withhugo.com/fonts/gilroy/gilroy-extrabold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

// Set base font size
html {
    font-size: percentage(math.div($base-font-size, 16px));
}

/**
 * Typography settings:
 * 1: Inherit base font size from root element (html)
 * 2: Always allow for font kerning
 * 3: https://developer.mozilla.org/en-US/docs/Web/CSS/text-size-adjust
 * 4: Enable advance type features (kerning and ligatures) if typeface supports them
 * 5: Make sure kerning and ligatures are enabled at all times
 * 6: Enable antialiasing where supported to make fonts smoother
 * 7: On smaller screens, smooth font looks blurry so turn off antialiasing
 */
body {
    font-size: 100%; /* 1 */
    font-kerning: normal; /* 2 */
    text-size-adjust: 100%; /* 3 */
    font-feature-settings: 'liga', 'kern'; /* 4 */
    text-rendering: optimizelegibility; /* 5 */

    @include font-smoothing(on); /* 6 */

    @include at-max($bp-small) {
        @include font-smoothing(off); /* 7 */
    }

    font-family:
        $font-name,
        system-ui,
        -apple-system,
        sans-serif;

    font-weight: $base-font-weight;
    line-height: $base-line-height;
    letter-spacing: $base-tracking;
}

// 1: Normalize all heading sizes
//    Reference: https://csswizardry.com/2016/02/managing-typography-on-large-apps/
// 2: Nuke the top margin to avoid margin collapsing and easier type control
//    Reference: https://csswizardry.com/2012/06/single-direction-margin-declarations/
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 2em; /* 1 */
    margin-top: 0; /* 2 */
}

p {
    margin-top: 0;
}

a {
    @include underline();
    color: $color-pink-100;
    transition: all 0.2s;

    &:hover,
    &:focus {
        color: $color-dark-pink-01;
    }

    &:focus-visible {
        outline: 1px dashed currentcolor;
    }

    @include dark-mode {
        color: $color-pink-30;

        &:hover,
        &:focus {
            color: $color-pink-10;
        }
    }
}
