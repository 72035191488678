.c-plans-toggle {
    position: relative;
    border: 2px solid $color-purple-10;
    border-radius: 99rem;
    padding: $space-1;

    // background: $color-white;
    margin: 0 auto;
    display: inline-block;
}

.c-plans-toggle__option {
    display: inline-block;
    vertical-align: middle;
}

.c-plans-toggle__label {
    display: flex;
    align-items: center;
    font-size: $font-size-3;
    text-decoration: underline;
    color: $color-dark-pink-01;
    padding: 7.5px;
    border-radius: 99rem;
    transition: all 0.2s ease-out;
    position: relative;
    overflow: hidden;
    min-height: 42px;
    z-index: 1;

    @include at-min($bp-tiny) {
        padding-left: $space-4;
        padding-right: $space-4;
    }

    &:after {
        @include absolute(0 0 0 0);
        content: '';
        background-color: $color-purple-10;
        transition: transform 0.15s ease-out;
        z-index: -1;
        transform: scale(0);
    }

    svg {
        flex: none;
    }

    span {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &:after {
            content: attr(data-title);
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-weight: $font-bold;
            font-size: $font-size-4;

            @media speech {
                display: none;
            }
        }
    }
}

.c-plans-toggle__input:checked + .c-plans-toggle__label {
    text-decoration: none;
    color: $color-purple-100;
    font-weight: $font-bold;

    @include at-min($bp-tiny) {
        font-size: $font-size-4;
    }

    &:after {
        transform: none;
    }
}
