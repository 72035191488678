.c-icon {
    @include size(1em);
    display: inline-block;
    stroke-width: 0;
    stroke: currentcolor;
    fill: currentcolor;

    path:not([fill='none']) {
        fill: inherit !important;
    }
}

.c-icon--yes,
.c-icon--no {
    box-shadow: $shadow-10;
    border-radius: 50%;
    width: 22px;
    height: auto;

    @include dark-mode {
        box-shadow: none;
        path {
            stroke: $color-white !important;
        }
    }
}

.c-icon--yes {
    fill: $color-white;

    path {
        stroke: $color-purple-100;
    }

    @include dark-mode {
        fill: $color-purple-100;
    }
}

.c-icon--no {
    fill: $color-white;

    path {
        stroke: $color-dark-gray-100;
    }

    @include dark-mode {
        fill: $color-pink-100;
    }
}

.c-icon--help-btn {
    @include dark-mode {
        rect,
        path {
            fill: $color-dark-gray-80;
            stroke: $color-dark-gray-80;
        }
        path[stroke='none'] {
            fill: $color-purple-30;
        }
    }
}
