.c-title {
    font-size: $font-size-8;
    font-weight: $font-bold;
    line-height: $line-height-1;
    margin-bottom: 1em;

    @include at-min($bp-medium) {
        font-size: $font-size-10;
    }

    @include at-min($bp-large) {
        font-size: $font-size-11;
    }
}

.c-title,
.c-title--colored {
    --base-color: #{$color-gray-100};
    --alt-color: #{$color-purple-100};

    @include dark-mode {
        --base-color: #{$color-white};
        --alt-color: #{$color-purple-30};
    }

    span {
        display: block;
        color: var(--base-color);
    }

    span + span {
        color: var(--alt-color);
    }
}

.c-title--inverted {
    span {
        display: block;
        color: var(--alt-color);
    }

    span + span {
        color: var(--base-color);
    }
}

// Magic on-off to fit title on iPhone 6+ in single line
.c-title--join {
    @include at-max($bp-small) {
        font-size: $font-size-7;
    }
}
