// Hide only visually, but have it available for screenreaders: h5bp.com/v
.u-sr-only {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
}

.u-visibility-hidden {
    visibility: hidden !important;
}

.u-hide {
    display: none !important;
}

.xs\:u-hide {
    @include at-max($bp-small) {
        display: none !important;
    }
}

.sm\:u-hide {
    @include at-min($bp-small) {
        @include at-max($bp-medium) {
            display: none !important;
        }
    }
}

.md\:u-hide {
    @include at-min($bp-medium) {
        @include at-max($bp-large) {
            display: none !important;
        }
    }
}

.lg\:u-hide {
    @include at-min($bp-large) {
        display: none !important;
    }
}
