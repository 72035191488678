// COLORS

$color-black:               hsl(0deg 0% 20%);
$color-white:               #FFFFFF;

$color-gray-100:            #434254;
$color-gray-80:             #696876;
$color-gray-50:             #A1A1A9;
$color-gray-30:             #C1C1C6;
$color-gray-20:             #D9D9DD;
$color-gray-10:             #ECECED;
$color-gray-05:             #F7F7F7;

$color-purple-100:          #6E66DB;
$color-purple-90:           #7D75DF;
$color-purple-80:           #8B85E2;
$color-purple-70:           #9A94E6;
$color-purple-60:           #A8A3E9;
$color-purple-50:           #B6B2ED;
$color-purple-40:           #C5C2F1;
$color-purple-30:           #D4D1F4;
$color-purple-20:           #E4E2F8;
$color-purple-15:           #E7E6F8; // only in LP gradient
$color-purple-10:           #F1F0FB;
$color-purple-05:           #F8F7FD;

$color-pink-100:            #B14DB3;
$color-pink-90:             #B95FBB;
$color-pink-80:             #C882CA;
$color-pink-70:             #C882CA;
$color-pink-60:             #D094D1;
$color-pink-50:             #D8A6D9;
$color-pink-40:             #E0B8E1;
$color-pink-30:             #E8CAE8;
$color-pink-20:             #F1DFF1;
$color-pink-10:             #F8EFF8;
$color-pink-05:             #FBF7FC;

// DARK

$color-dark-gray-100:       #21212A;
$color-dark-gray-95:        #2C2C35;
$color-dark-gray-90:        #37373F;
$color-dark-gray-80:        #4D4D55;
$color-dark-gray-70:        #64646A;
$color-dark-gray-60:        #7A7A7F;
$color-dark-gray-50:        #909094;
$color-dark-gray-40:        #A6A6AA;
$color-dark-gray-30:        #BCBCBF;
$color-dark-gray-20:        #D3D3D4;
$color-dark-gray-10:        #E9E9EA;
$color-dark-gray-05:        #F4F4F4;

$color-dark-purple-01:      #544F99;
$color-dark-purple-02:      #38365F;
$color-dark-purple-03:      #5847AD;

$color-dark-pink-01:        #832884;
$color-dark-pink-02:        #86408A;
$color-dark-pink-03:        #723A77;

// SECONDARY

$color-green-01:            #A0CA28;
$color-green-02:            #B6DD4C;
$color-green-03:            #D0F373;

$color-yellow-01:           #FFDF67;

$color-red-01:              #F45265;
$color-red-02:              #FF99A5;

// GRADIENTS

$gradient-base: linear-gradient(97deg, $color-purple-100, #ED56A1 160%);
$gradient-dark: linear-gradient(97deg, #8345C9, #A12A65 160%);
$gradient-alt: linear-gradient(93deg, $color-dark-purple-03 7%, $color-purple-100 130%);

$gradient-bg-1: linear-gradient(0deg, $color-purple-15, $color-gray-05);
$gradient-bg-2: linear-gradient(0deg, $color-purple-15, $color-white);
$gradient-bg-3: linear-gradient(0deg, $color-purple-10, $color-white);
$gradient-bg-4: linear-gradient(0deg, $color-purple-15, $color-purple-10);
$gradient-bg-5: linear-gradient(0deg, #2F2F4C,  $color-dark-purple-02);
$gradient-bg-6: linear-gradient(180deg, $color-dark-gray-100,  $color-dark-purple-02);

// SHADOWS

$shadow-5: 1px 1px 2px rgba($color-black, 0.1); // nav arrows
$shadow-10: 2px 2px 8px rgba($color-black, 0.1); // header, slider nav
$shadow-20: 4px 8px 10px rgba($color-black, 0.1); // play btn
$shadow-25: 4px 4px 12px rgba($color-black, 0.1); // quote
$shadow-30: 8px 8px 20px rgba($color-black, 0.1); // cta and zendesk
$shadow-40: 15px 25px 20px rgba($color-black, 0.25); // hhw
$shadow-50: 0 10px 50px rgba($color-black, 0.05); // media item

$shadow-200: 0 2px 4px rgba($color-black, 0.1); // small buttons
$shadow-300: 0 2px 8px rgba($color-black, 0.05); // large buttons
$shadow-500: 2px 2px 8px rgba($color-black, 0.2); // help icon
