// Styles related to page main header.

.c-header {
    position: sticky;
    top: 0;
    z-index: z('header');

    height: $header-height;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:before {
        content: '';

        @include absolute(0);
        background: rgba($color-white, 0.8);
        backdrop-filter: blur(5px);

        @include dark-mode {
            background: rgba($color-black, 0.8);
        }
    }
}

.c-header__inner {
    width: 100%;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.c-header__logo {
    position: relative;
    margin-right: auto;
    flex: none;
}

.c-header__overlay {
    @include fixed(0);
    pointer-events: none;
    background-color: transparent;
    visibility: hidden;
    opacity: 0;
    transition: all 100ms ease-out;

    &.is-visible {
        pointer-events: auto;
        background-color: rgba($color-gray-100, 0.75);
        visibility: visible;
        opacity: 1;
        transition-duration: 300ms;

        @include dark-mode {
            background-color: rgba($color-dark-gray-100, 0.75);
        }
    }

    @include at-min($bp-large) {
        display: none;
    }
}

.c-header__navbar {
    @include fixed(0 0 0 null);
    @include scrollable();
    z-index: z('header_nav');
    width: 80%;
    max-width: $nav-drawer;
    background-color: $color-white;
    transform: translateX(102%);
    transition: all 300ms ease-out;
    pointer-events: none;
    content-visibility: hidden;
    contain: layout;

    padding-top: 100px;
    padding-top: clamp(100px, 20vw, 120px);

    &.is-open {
        pointer-events: auto;
        transform: translateX(0);
        content-visibility: visible;
    }

    @include at-min($bp-large) {
        display: none;
    }

    @include dark-mode {
        background-color: $color-dark-gray-90;
    }
}

// Links in drawer nav
.c-header__nav-link {
    font-size: $font-size-4;
    display: block;
    width: 100%;
    text-align: left;
    text-decoration: none;
    color: $color-gray-100;
    padding: $space-1;
    padding-right: $space-4;
    padding-left: $space-8;
    margin-bottom: $space-2;

    @include at-min($bp-small) {
        margin-bottom: $space-4;
        padding-left: $space-10;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
        outline: none;
    }

    &.is-active {
        font-weight: $font-bold;
        color: $color-pink-90;
        box-shadow: inset 8px 0 0 currentcolor;
    }

    @include dark-mode {
        color: $color-white;

        &.is-active {
            color: $color-pink-30;
        }
    }
}

// Wrapper for buttons in drawer nav
.c-header__menu-actions {
    margin-top: $space-6;
    margin-left: $space-8;
    margin-right: $space-5;
    max-width: $nav-drawer * 0.5;

    @include at-min($bp-small) {
        margin-left: $space-10;
        margin-top: $space-10;
    }
}

// Common class for button in header on desktop
.c-header__navbar-btn {
    margin-left: $space-2;

    &:not(.c-header__menu) {
        font-size: $font-size-2;
    }
}

// Log in btn
.c-header__acc {
    @include at-max($bp-tiny) {
        display: none !important;
    }
}

// CTA btn
.c-header__cta {
    @include at-max($bp-small) {
        transform: translateY(0%);
        transition: transform 0.2s;

        .hide-cta & {
            transform: translateY(-150%);
        }
    }
}

// Hamburger
.c-header__menu {
    flex: none;
    z-index: z('header_menu');
    margin-right: -1 * $space-2;

    @include at-min($bp-large) {
        display: none;
    }
}

// Header links on desktop
.c-header__link {
    @include at-max($bp-large) {
        display: none;
    }

    font-size: $font-size-2;
    color: $color-gray-100;
    margin: 0 $space-3;
    z-index: 1;
    text-decoration: none;

    &:focus,
    &:hover {
        text-decoration: underline;
    }

    &.is-active {
        color: $color-pink-100;
        text-decoration: underline;
    }

    @include dark-mode {
        color: $color-white;

        &.is-active {
            color: $color-pink-30;
        }
    }
}
