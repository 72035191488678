.u-font-1 {
    font-size: $font-size-1 !important;
}

.u-font-2 {
    font-size: $font-size-2 !important;
}

.u-font-3 {
    font-size: $font-size-3 !important;
}

.u-font-4 {
    font-size: $font-size-4 !important;
}

.u-font-5 {
    font-size: $font-size-5 !important;
}

.u-font-6 {
    font-size: $font-size-6 !important;
}

.u-font-7 {
    font-size: $font-size-7 !important;
}

.u-font-8 {
    font-size: $font-size-8 !important;
}

.u-font-9 {
    font-size: $font-size-9 !important;
}

.u-font-10 {
    font-size: $font-size-10 !important;
}

.u-font-11 {
    font-size: $font-size-11 !important;
}

.u-font-12 {
    font-size: $font-size-12 !important;
}

.u-font-normal {
    font-weight: $font-normal !important;
}

.u-font-medium {
    font-weight: $font-medium !important;
}

.u-font-bold {
    font-weight: $font-bold !important;
}

.u-leading-1 {
    line-height: $line-height-1 !important;
}

.u-leading-2 {
    line-height: $line-height-2 !important;
}

.u-leading-3 {
    line-height: $line-height-3 !important;
}

.u-leading-4 {
    line-height: $line-height-4 !important;
}

.u-leading-5 {
    line-height: $line-height-5 !important;
}

.u-leading-6 {
    line-height: $line-height-6 !important;
}

.u-text-uppercase {
    text-transform: uppercase !important;
}

.u-text-underline {
    text-decoration: underline !important;
}

.u-text-no-underline {
    text-decoration: none !important;
}

.u-text-center {
    text-align: center !important;
}

.u-text-right {
    text-align: right !important;
}

.u-text-left {
    text-align: left !important;
}

.u-align-baseline {
    vertical-align: baseline !important;
}

.u-align-top {
    vertical-align: top !important;
}

.u-align-middle {
    vertical-align: middle !important;
}

.u-align-bottom {
    vertical-align: bottom !important;
}

.u-align-text-bottom {
    vertical-align: text-bottom !important;
}

@include at-min($bp-small) {
    .sm\:u-font-1 {
        font-size: $font-size-1 !important;
    }
    .sm\:u-font-2 {
        font-size: $font-size-2 !important;
    }
    .sm\:u-font-3 {
        font-size: $font-size-3 !important;
    }
    .sm\:u-font-4 {
        font-size: $font-size-4 !important;
    }
    .sm\:u-font-5 {
        font-size: $font-size-5 !important;
    }
    .sm\:u-font-6 {
        font-size: $font-size-6 !important;
    }
    .sm\:u-font-7 {
        font-size: $font-size-7 !important;
    }
    .sm\:u-font-8 {
        font-size: $font-size-8 !important;
    }
    .sm\:u-font-9 {
        font-size: $font-size-9 !important;
    }
    .sm\:u-font-10 {
        font-size: $font-size-10 !important;
    }
    .sm\:u-font-11 {
        font-size: $font-size-11 !important;
    }
    .sm\:u-font-12 {
        font-size: $font-size-12 !important;
    }
}

@include at-min($bp-medium) {
    .md\:u-font-1 {
        font-size: $font-size-1 !important;
    }
    .md\:u-font-2 {
        font-size: $font-size-2 !important;
    }
    .md\:u-font-3 {
        font-size: $font-size-3 !important;
    }
    .md\:u-font-4 {
        font-size: $font-size-4 !important;
    }
    .md\:u-font-5 {
        font-size: $font-size-5 !important;
    }
    .md\:u-font-6 {
        font-size: $font-size-6 !important;
    }
    .md\:u-font-7 {
        font-size: $font-size-7 !important;
    }
    .md\:u-font-8 {
        font-size: $font-size-8 !important;
    }
    .md\:u-font-9 {
        font-size: $font-size-9 !important;
    }
    .md\:u-font-10 {
        font-size: $font-size-10 !important;
    }
    .md\:u-font-11 {
        font-size: $font-size-11 !important;
    }
    .md\:u-font-12 {
        font-size: $font-size-12 !important;
    }
}

@include at-min($bp-large) {
    .lg\:u-font-1 {
        font-size: $font-size-1 !important;
    }
    .lg\:u-font-2 {
        font-size: $font-size-2 !important;
    }
    .lg\:u-font-3 {
        font-size: $font-size-3 !important;
    }
    .lg\:u-font-4 {
        font-size: $font-size-4 !important;
    }
    .lg\:u-font-5 {
        font-size: $font-size-5 !important;
    }
    .lg\:u-font-6 {
        font-size: $font-size-6 !important;
    }
    .lg\:u-font-7 {
        font-size: $font-size-7 !important;
    }
    .lg\:u-font-8 {
        font-size: $font-size-8 !important;
    }
    .lg\:u-font-9 {
        font-size: $font-size-9 !important;
    }
    .lg\:u-font-10 {
        font-size: $font-size-10 !important;
    }
    .lg\:u-font-11 {
        font-size: $font-size-11 !important;
    }
    .lg\:u-font-12 {
        font-size: $font-size-12 !important;
    }
}
