@use 'sass:math';

.l-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    margin: -1 * $space-1;
    [class*='l-grid__col'] {
        padding: $space-1;
    }

    @include at-min($bp-medium) {
        margin: -1 * $space-3;
        [class*='l-grid__col'] {
            padding: $space-3;
        }
    }
}

.l-grid--center {
    justify-content: center;
}

.l-grid__col {
    flex: 1 1 0%;
    max-width: 100%;
}

@for $i from 1 through 12 {
    .l-grid__col-#{$i} {
        flex: 0 0 percentage(math.div($i, 12));
        max-width: percentage(math.div($i, 12));
    }
}

@for $i from 1 through 12 {
    .l-grid__offset--#{$i} {
        margin-left: percentage(math.div($i, 12));
    }
}

$breakpoints: (
    'sm': $bp-small,
    'md': $bp-medium,
    'lg': $bp-large,
);

@each $bp-name, $bp-value in $breakpoints {
    @for $i from 1 through 12 {
        @include at-min($bp-value) {
            .#{$bp-name}\:l-grid__col-#{$i} {
                flex: 0 0 percentage(math.div($i, 12));
                max-width: percentage(math.div($i, 12));
            }
        }
    }
}

@each $bp-name, $bp-value in $breakpoints {
    @for $i from 1 through 12 {
        @include at-min($bp-value) {
            .#{$bp-name}\:l-grid__offset--#{$i} {
                margin-left: percentage(math.div($i, 12));
            }
        }
    }
}
